import { ILauncherSpace } from './interfaces';

export const ENVIRONMENT: 'dev' | 'stage' | 'prod' | 'bits' | 'k5' | 'nwx' | 'metro' = 'stage';
export const DEFAULT_MULTISPACE_URL = 'https://multispace-stage.hspx.de';
export const DEFAULT_PLATFORM_URL = 'https://app-stage.hellospaces.de/';
export const DEFAULT_PLATFORM_SHARE_URL = 'https://app-stage.hellospaces.de/app/spacesummary/';
export const PRESET_SPACEIDS: string[] | undefined = undefined;
export const PRESET_SPACE_FALLBACK: ILauncherSpace | undefined = undefined;
export const SENTRY_DSN = 'https://6b91cea45ccb4b8eb8bb43e2521548f7@sentry.hspx.de/6';
export const IOS_APPSTORE_URL = 'https://apps.apple.com/de/app/hellospaces/id1615726233'
export const GOOGLE_PLAYSTORE_URL = 'https://play.google.com/store/apps/details?id=de.hellospaces.app'
export const MIN_BUILD_VERSION_IOS = 8;
export const MIN_BUILD_VERSION_ANDROID = 11;
export const APP_STORE_URL_IOS = undefined;
export const APP_STORE_URL_ANDROID = undefined;
export const DEEPLINK_SHORT = 'hspxstage://';
export const APP_LANGUAGE: 'de' | 'en' | undefined = undefined;

export const QuotaLimits = {
	attendees: 50,
	admins: 1,
	moderators: 1
};
